<template>
  <div class="container">
    <div class="row justify-content-center my-5 mx-1">
      <div class="form-contain signIn col-xs-12 col-md-8 col-lg-6 px-3">
        <div class="form-header" :class="t_ar"> {{ $t("verifyAccount") }} </div>
        <div class="form-subheader" :class="t_ar"> {{ $t("TheCodeSent", {
            account: isEmail ? $t("col_email") :
              $t("col_phone"),
          })
        }} </div>
        <!-- <div class="login-forgot-password pointer" @click="resendCode">{{$t('resendCode')}}</div> -->
        <form @submit.prevent="verifyAccount" class="mt-5">
          <div class="form-group row">
            <div class="col-12">
              <label class="input-label" :class="f_ar"> {{ $t("verificationCode") }} </label>
              <input type="text" v-model="code" max="4" class="form-control text-input"
                :placeholder="$t('verificationCode')" :class="t_ar" required />
            </div>
          </div>
          <div class="text-center">
            <button :disabled="loading" :class="loading ? 'btn-secondary' : ''" type="submit"
              class="btn-pri-lg my-4 mx-0 w-100 signUp-btn"> {{ $t("verifyAccount") }} </button>
          </div>
          <!-- <div class=" form-subheader">
              {{$t('CantfindCode')}}
              <a class="form-subheader strong text-underline pointer" @click='goSignup'>{{$t('resendVerifyCode')}}</a>
            </div> -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import cookies from "vue-cookies";

export default {
  data() {
    return {
      code: "",
      disableBtn: false,
    };
  },
  mounted() {
    if (!this.$route.params.identifier) {
      this.$router.push({
        name: "Register",
      });
    }
  },
  methods: {
    ...mapActions(["verifyUser", "userRegisterPhone", "userRegisterEmail"]),
    ...mapMutations(["showMsg"]),
    verifyAccount() {
      let payload = {
        otp: this.code,
      };
      let identifier = this.$route.params.identifier;
      this.isEmail
        ? (payload = {
          ...payload,
          email: identifier,
        })
        : (payload = {
          ...payload,
          phone: identifier,
          phone_code: "+49",
        });
      this.verifyUser(payload)
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("verifyCodeSuccess"),
            close: false,
          });
          setTimeout(() => {
            this.$router.push({
              name: "ProfileInfo",
            });
          }, 2000);
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("verifyCodeError"),
            close: false,
          });
        });
    },
    resendCode() {
      let identifier = this.$route.params.identifier;
      if (this.isEmail) {
        this.userRegisterEmail({
          email: identifier,
          api_token: cookies.get("user_token"),
        })
          .then((res) => {
            this.showMsg({
              type: "success",
              text: this.$t("CodeResendSuccessful"),
            });
            this.disableBtn = true;
            setTimeout(() => {
              this.disableBtn = false;
            }, 5000);
          })
          .catch((err) => {
            this.showMsg({
              type: "error",
              text: this.$t("CodeResendError"),
            });
          });
      } else
        this.userRegisterPhone({
          phone: identifier,
          phone_code: "+49",
          api_token: cookies.get("user_token"),
        })
          .then((res) => {
            this.showMsg({
              type: "success",
              text: this.$t("CodeResendSuccessful"),
            });
            this.disableBtn = true;
            setTimeout(() => {
              this.disableBtn = false;
            }, 5000);
          })
          .catch((err) => {
            this.showMsg({
              type: "error",
              text: this.$t("CodeResendError"),
            });
          });
    },
  },
  computed: {
    ...mapGetters(["isVerified", "loading"]),
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    isEmail() {
      return this.$route.params.isEmail;
    },
  },
};
</script>