<template>
  <div class="container login-screen">
    <div class="row justify-content-center my-5 mx-1 w-100 ">
      <div class="form-contain signIn col-xs-12 col-md-8 col-lg-6 px-2 px-md-5">
        <div class="form-header mb-2" :class="t_ar"> {{ $t("logIn") }} </div>
        <div class="form-subheader mb-lg-5 mb-2 mt-0" :class="t_ar"> {{ $t("fillInFields") }} </div>
        <form @submit.prevent="Login">
          <div class="form-group row justify-content-center">
            <div class="col-12 px-0">
              <label class="input-label" :class="f_ar"> {{ isEmail ? $t("col_email") : $t("col_phone") }} </label>
              <input v-if="isEmail" :dir="isEmail ? 'ltr' : ''" :type="isEmail ? 'email' : 'text'" v-model="identifier"
                class="form-control text-input" :placeholder="isEmail ? $t('col_email') : $t('col_phone')" :class="t_ar"
                required id="email" />
              <input v-else :type="isEmail ? 'email' : 'text'" v-model="identifier" class="form-control text-input"
                :placeholder="isEmail ? $t('col_email') : $t('col_phone')" :class="t_ar" required id="phone"
                v-mask="'49###############'" :masked="true" @blur="validatePhone" />
            </div>
            <small v-if="!isEmail && (col.error || col.vaild)" :class="[col.valid ? 'text-success' : 'text-error']"
              class="my-2"> {{ col.valid || col.error }} </small>
          </div>
          <div class="form-group row">
            <div class="col-12 px-0">
              <label class="input-label" :class="f_ar"> {{ $t("password") }} </label>
              <input type="password" v-model="password" class="form-control text-input" :placeholder="$t('password')"
                required :class="[password.length > 5 && password.length < 26 ? 'is-valid' : 'is-invalid']" />
            </div>
          </div>
          <!-- <div class="row mt-5 mb-4">
                <div class="col-lg-12" :class="flex_ar">
                  <div class="text-underline"
                    :class="t_ar"
                  >
                    <a class="login-forgot-password px-2 pointer">{{$t('forgotPassword')}}</a>
                  </div>
                </div>
              </div> -->
          <div class="form-group text-center mt-lg-5 mt-3 mb-0">
            <button class="btn-pri-lg py-2 w-50"
              :disabled="!loading && password.length > 5 && password.length < 26 ? false : true"
              :class="loading ? 'btn-secondary' : ''"> {{ $t("logIn") }} </button>
          </div>
          <div class="form-group mb-4 mt-0 mt-lg-4">
            <div class="row text-center">
              <div class="col-lg-12 px-0">
                <div class="form-subheader"></div>
                <div class="form-subheader"> {{ $t("DontHaveAccount") }} <a
                    class="form-subheader strong text-underline pointer" @click="goSignup">{{ $t("signUp") }}</a>
                  <span class="mx-3">{{ $t("OR") }}</span> {{ $t("UseOtherLogin") }} <a
                    class="form-subheader strong text-underline pointer" @click="changeLoginMethod">{{ isEmail ?
                        $t("col_phone") : $t("col_email")
                    }}</a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import cookies from "vue-cookies";
export default {
  mounted() {
    if (this.$route.params.isEmail) {
      this.loginMethod = this.$route.params.isEmail ? "email" : "phone";
    }
  },
  data() {
    return {
      loginMethod: "email",
      identifier: "",
      password: "",
      col: {},
    };
  },
  computed: {
    ...mapGetters(["loading", "user", "userAddresses"]),
    ar() {
      return this.$i18n.locale == "ar";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    isEmail() {
      return this.loginMethod == "email";
    },
  },
  methods: {
    ...mapActions([
      "userLogin",
      "userRegisterEmail",
      "userRegisterPhone",
      "checkPhone",
      "getUserAddresses",
    ]),
    ...mapMutations(["showMsg"]),
    goSignup() {
      this.$router.push({
        name: "Register",
      });
    },
    checkDefaultAddress(add) {
      if (
        add.longitude &&
        add.longitude == this.user.longitude &&
        add.lattitude &&
        add.lattitude == this.user.latitude
      ) {
        return true;
      } else return false;
    },
    changeLoginMethod() {
      this.loginMethod == "email"
        ? (this.loginMethod = "phone")
        : (this.loginMethod = "email");
    },
    validatePhone() {
      if (!empty(this.identifier)) {
        this.$forceUpdate();
        this.checkPhone(this.identifier)
          .then((res) => {
            if (res.data.data == true) {
              this.col.valid = this.$t("VaildNumber");
              this.$forceUpdate();
            } else {
              $("#phone").select();
              this.col.error = this.$t("notVaildNumber");
              this.col.valid = null;
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            $("#phone").select();
            this.col.error = this.$t("notVaildNumber");
            this.col.valid = null;
            this.$forceUpdate();
          });
      } else this.$forceUpdate();
    },
    Login() {
      let payload = { password: this.password };
      this.isEmail
        ? (payload = {
          ...payload,
          email: this.identifier,
        })
        : (payload = {
          ...payload,
          phone: this.identifier,
          phone_code: "+49",
        });
      this.userLogin(payload)
        .then((res) => {
          if (res.is_verified === 0) {
            this.showMsg({
              text: this.$t("accountNotVerified"),
              type: "warning",
              close: false,
            });
            setTimeout(() => {
              this.$router.push({
                name: "verifyAccount",
                params: {
                  ...payload,
                  isEmail: this.isEmail,
                },
              });
            }, 2000);
          } else {
            this.showMsg({
              type: "success",
              text: this.$t("LoginSuccessfull"),
              close: false,
            });
            this.getUserAddresses(this.user.api_token).then((res) => {
              console.log(res, "asdasd");
              this.userAddresses.forEach((el) => {
                this.checkDefaultAddress(el) == true
                  ? cookies.set("defaultAddressId", el.id)
                  : "";
              });
            });
            setTimeout(() => {
              this.$router.push({
                name: localStorage.getItem("loginForward") || "Home",
                params: {
                  locale: this.$i18n.locale,
                },
              });
            }, 2000);
          }
        })
        .catch((err) => {
          console.log("🚀 ~ file: UserLogin.vue ~ line 244 ~ Login ~ err", err);
          this.showMsg({
            text: this.$t("EmailORPassworNotCorrect"),
            type: "error",
            close: false,
          });
        });
    },
  },
};
</script>
